import React, {useEffect, useRef, useState} from "react";
import {Alert, Form, Table} from 'react-bootstrap';
import {storage} from "../../../Firebase";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

const SGrDeviceTable = (props) => {
  return (
    <Table>
      <tbody>
      <tr>
        <th className="text-start">Name</th>
        <td className="text-end">{props.device.name}</td>
      </tr>
      <tr>
        <th className="text-start">Product Category</th>
        <td className="text-end">{props.device.category}</td>
      </tr>
      </tbody>
    </Table>
  );
};

const SGrDeviceForm = (props) => {
  const [placeholders, setPlaceholders] = useState({});
  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    try {
      // Step 1: Read the file content as text
      const xmlContent = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
      });
  
      // Step 2: Extract placeholders
      const matches = xmlContent.match(/{{(.*?)}}/g) || [];
      const newPlaceholders = {};
      matches.forEach((match) => {
        const key = match.replace(/{{|}}/g, "").trim();
        newPlaceholders[key] = ""; // Initialize with an empty string
      });
      setPlaceholders(newPlaceholders);
      updateConfigurationValues(newPlaceholders); // Update JSON string
  
      // Step 3: Extract category and update device state
      const categoryMatch = xmlContent.match(/<deviceCategory>(.*?)<\/deviceCategory>/);
      const updatedDevice = {
        ...props.device,
        xmlName: file.name,
        ...(categoryMatch ? { category: categoryMatch[1] } : {}),
      };
      await props.setDevice(updatedDevice);
  
      // Step 4: Generate a unique file reference and update device state
      const xmlRef = `sgrxml/${uuidv4()}-${file.name}`;
      const storageRef = ref(storage, xmlRef);
      await props.setDevice((prevDevice) => ({ ...prevDevice, xmlRef }));
  
      // Step 5: Upload the file to storage and get the download URL
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      console.log("File uploaded successfully. Download URL:", downloadURL);
  
      // Step 6: Update the device state with the download URL
      await props.setDevice((prevDevice) => ({ ...prevDevice, xmlUrl: downloadURL }));
    } catch (error) {
      console.error("Error handling file upload:", error);
    }
  };
  

  // Populate placeholders from existing device (if edited)
  useEffect(() => {
    // populate placeholders from existing device (if edited)
    if (props.device.configurationValues) {
      const newPlaceholders = {};
      props.device.configurationValues.forEach((placeholder) => {
        newPlaceholders[placeholder.name] = placeholder.val;
      });
      setPlaceholders(newPlaceholders);
    };
  }, []);

  const handlePlaceholderChange = (e) => {
    const {name, value} = e.target;
    const updatedPlaceholders = {
      ...placeholders,
      [name]: value,
    };
    setPlaceholders(updatedPlaceholders);
    updateConfigurationValues(updatedPlaceholders); // Update JSON string
  };

  const updateConfigurationValues = (placeholders) => {
    const placeholderArray = Object.keys(placeholders).map((key) => ({
      name: key,
      val: placeholders[key],
    }));
    props.setDevice({...props.device, configurationValues: placeholderArray});
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // verify all fields are filled before saving
  useEffect(() => {
    
    if(Object.values(placeholders).every((val) => val !== "") && props.device.name !== "" && props.device.category == "SubMeterElectricity") {
      props.setSaveDisabled(false);
    } else {
      props.setSaveDisabled(true);
    }
  }, [props.device]);

  return (
    <Form>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={props.device.name}
          onChange={(e) => props.setDevice({...props.device, name: e.target.value})}
        />
      </Form.Group>

      {/* Separate container for highlighted placeholder fields */}
      <div className="placeholder-container">
        <Form.Group>
          <div className="d-flex align-items-center">
            <img src="/SGR_LOGO.svg" alt="Smart Gr!d Ready - Konfiguration"/>
            <p className="ms-2">- XML Konfiguration</p>
          </div>
          <input
            className="xml-file"
            type="file"
            name="xml"
            accept=".xml"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <button
            type="button"
            className="custom-file-button"
            onClick={handleButtonClick}
          >
            {(props.device.xmlName) || "XML Datei auswählen"}
          </button>
        </Form.Group>
        <hr/>
        {Object.keys(placeholders).map((placeholder) => (
          <Form.Group key={placeholder} className="placeholder-field">
            <Form.Label>{placeholder}</Form.Label>
            <Form.Control
              type="text"
              name={placeholder}
              value={placeholders[placeholder]}
              onChange={handlePlaceholderChange}
            />
          </Form.Group>
        ))}
      </div>

      <Form.Group>
        <Form.Label>Kategorie: {props.device.category}</Form.Label>
        {props.device.category && props.device.category !== "SubMeterElectricity" && 
        
          <Alert variant="warning">
            Zur Zeit werden nur Geräte der Kategorie "SubMeterElectricity" unterstützt.
          </Alert>
        }
        
      </Form.Group>
    </Form>
  );
};

export {SGrDeviceTable, SGrDeviceForm};
