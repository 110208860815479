import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceArea, ResponsiveContainer, Label
} from 'recharts';
import { Spinner } from 'react-bootstrap';
import { getAuth } from "firebase/auth";

const colors = ["#003f5c", "#bc5090", "#ffa600"];

const Lchart = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [zoomState, setZoomState] = useState({
    refAreaLeft: '',
    refAreaRight: '',
    left: 'dataMin',
    right: 'dataMax',
    top: 'dataMax+1',
    bottom: 'dataMin',
  });

  const getEnd = () => {
      if (props.end === undefined){
        return (new Date()).toISOString();
      }
      return props.end
  };

  const zoom = () => {
    if (zoomState.refAreaLeft === zoomState.refAreaRight || zoomState.refAreaRight === '') {
      setZoomState({ ...zoomState, refAreaLeft: '', refAreaRight: '' });
      return;
    }

    // Swap if left is more than right
    if (zoomState.refAreaLeft > zoomState.refAreaRight) {
      [zoomState.refAreaLeft, zoomState.refAreaRight] = [zoomState.refAreaRight, zoomState.refAreaLeft];
    }

    setZoomState({
      ...zoomState,
      left: zoomState.left,
      right: zoomState.right,
      refAreaLeft: '',
      refAreaRight: '',
    });

    //console.log(`zoomState: ${JSON.stringify(zoomState, null, 2)}`)
    props.updateAbsoluteRange(zoomState.refAreaLeft, zoomState.refAreaRight);
  };

  const getData = async() => {
    setLoading(true);
    const idTokenResult = await getAuth().currentUser.getIdTokenResult();
    //console.log(`start: ${props.start}`)
    //console.log(`end: ${getEnd()}`)
    const postData = {
      id: props.eid,
      filters: props.chart.filters,
      nrOfDataPoints: props.nrOfDataPoints,
      start: props.start,
      stop: getEnd(),
    };

    const headers = {
      'Authorization': 'Bearer ' + idTokenResult.token,
    };
    axios.post(props.influxAPIUrl, postData, {headers}).then(response => {
      const dict = {};
      for (const val of response.data) {
        const value = Math.round(val._value * 100) / 100;
        dict[val._time] = {...dict[val._time], _time: val._time, [val[props.chart.sortByKey]]: value};
      }

      const dKeys = [...new Set(response.data.map(item => item[props.chart.sortByKey]))];
      setDataKeys(dKeys);
      const arr = Object.values(dict);
      setData(arr)
      setLoading(false)
    }).catch(err => console.log(err));
  }

  useEffect(() => {
    if(props.start && props.end) {
      getData();
    }
  }, [props.start, props.end]);

  const CustomTooltip = ({ active, payload, label }) => {
    // display all the data with according colors in a readable format and white background
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{backgroundColor: 'white', padding: '5px', border: '1px solid #ccc'}}>
          <p className="label">{`${moment(label).format('DD.MM.YYYY HH:mm')}`}</p>
          {
            payload.map((item, index) => (
              <p key={index} style={{color: item.color}}>{`${item.name}: ${item.value}`}</p>
            ))
          }
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {loading ? <Spinner animation="border" /> :<>
        {
          data && dataKeys ? (
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={data}
                onMouseDown={(e) => setZoomState({ ...zoomState, refAreaLeft: e.activeLabel })}
                onMouseMove={(e) => zoomState.refAreaLeft && setZoomState({ ...zoomState, refAreaRight: e.activeLabel })}
                onMouseUp={zoom}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="_time"
                  allowDataOverflow
                  domain={[zoomState.left, zoomState.right]}
                  tickFormatter={(str) => moment(str).format('DD.MM HH:mm')}
                >
                </XAxis>
                <YAxis domain={['auto', 'auto']} >
                  <Label value={props.chart.name} offset={7} position="insideBottomLeft" angle="-90" />
                </YAxis>
                <Tooltip content={<CustomTooltip />} />
                {dataKeys.map((k, index) => (
                  <Line key={k} type={props.chart.lineType || "monotone"} dataKey={k} stroke={colors[index]} dot={false} />
                ))}
                {
                  (zoomState.refAreaLeft && zoomState.refAreaRight) ? (
                    <ReferenceArea
                      x1={zoomState.refAreaLeft}
                      x2={zoomState.refAreaRight}
                      strokeOpacity={0.3}
                    />
                  ) : null
                }
              </LineChart>
            </ResponsiveContainer>
          ) : <Spinner animation="border" />
        }

      </>
      }
    </div>
  );
}

export default Lchart;
