import React, {useEffect, useState} from "react";

import {Table, Form, Button} from 'react-bootstrap';

const ShellyPro3EmTable = (props) => {
  return (
    <Table>
      <tbody>
      <tr>
        <th className="text-start">Name</th>
        <td className="text-end">{props.device.name}</td>
      </tr>
      <tr>
        <th className="text-start">API URL</th>
        <td className="text-end">{props.device.apiUrl}</td>
      </tr>
      <tr>
        <th className="text-start">Gerät ID</th>
        <td className="text-end">{props.device.id}</td>
      </tr>
      <tr>
        <th className="text-start">Auth Key</th>
        <td className="text-end"> {'************'}</td>
      </tr>
      </tbody>
    </Table>
  );
};


const ShellyPro3EmForm = (props) => {
  const [showAuthKey, setShowAuthKey] = useState(false);

  const toggleShowAuthKey = () => {
    setShowAuthKey(!showAuthKey);
  };

  // verify all fields are filled before saving
  useEffect(() => {
    if (props.device.name && props.device.apiUrl && props.device.authKey && props.device.id) {
      props.setSaveDisabled(false);
    } else {
      props.setSaveDisabled(true);
    }
  }, [props.device]);

  return (
    <Form>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={props.device.name}
          onChange={props.handleChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>API URL</Form.Label>
        <Form.Control
          type="text"
          name="apiUrl"
          value={props.device.apiUrl}
          onChange={props.handleChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>Auth Key</Form.Label>
        <div className="d-flex">
          <Form.Control
            type={showAuthKey ? "text" : "password"}
            name="authKey"
            value={props.device.authKey}
            onChange={props.handleChange}
            className="me-2"/>
          <Button onClick={toggleShowAuthKey} variant="outline-secondary">
            {showAuthKey ? 'Verbergen' : 'Zeigen'}
          </Button>
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>ID</Form.Label>
        <Form.Control
          type="text"
          name="id"
          value={props.device.id}
          onChange={props.handleChange}/>
      </Form.Group>
    </Form>
  );
};


export {ShellyPro3EmTable, ShellyPro3EmForm};