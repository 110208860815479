export const smartPiCharts = [
  {
    filters: [
      {name: "tags_1", value: ["devices"]},
      {name: "tags_2", value: ["SmartPi"]},
      {name: "tags_3", value: ["SmartPi"]},
      {name: "tags_4", value: ["u"]},
    ],
    name: "Spannung [V]",
    sortByKey: "tags_5",
    type: "TimeSeriesLineChart",
  },
  {
    filters: [
      {name: "tags_1", value: ["devices"]},
      {name: "tags_2", value: ["SmartPi"]},
      {name: "tags_3", value: ["SmartPi"]},
      {name: "tags_4", value: ["i"]},
    ],
    name: "Strom [A]",
    sortByKey: "tags_5",
    type: "TimeSeriesLineChart",
  },
  {
    filters: [
      {name: "tags_1", value: ["devices"]},
      {name: "tags_2", value: ["SmartPi"]},
      {name: "tags_3", value: ["SmartPi"]},
      {name: "tags_4", value: ["p"]},
    ],
    name: "Wirkleistung [W]",
    sortByKey: "tags_5",
    type: "TimeSeriesLineChart",
  },
  {
    filters: [
      {name: "tags_1", value: ["devices"]},
      {name: "tags_2", value: ["SmartPi"]},
      {name: "tags_3", value: ["SmartPi"]},
      {name: "tags_4", value: ["cosphi"]},
    ],
    name: "cos(phi)",
    sortByKey: "tags_5",
    type: "TimeSeriesLineChart",
  },
  {
    filters: [
      {name: "tags_1", value: ["devices"]},
      {name: "tags_2", value: ["SmartPi"]},
      {name: "tags_3", value: ["SmartPi"]},
      {name: "tags_4", value: ["f"]},
    ],
    name: "Frequenz [Hz]",
    sortByKey: "tags_5",
    type: "TimeSeriesLineChart",
  },
]

export const modbusRTUMeterChart = (name) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusRTUMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["u"]},
      ],
      name: "Spannung [V]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusRTUMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["i"]},
      ],
      name: "Strom [A]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusRTUMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["p"]},
      ],
      name: "Wirkleistung [W]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusRTUMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["cosphi"]},
      ],
      name: "cos(phi)",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusRTUMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["f"]},
      ],
      name: "Frequenz [Hz]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
  ]
}

export const modbusRTUMeterZEVChart = (name) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusRTUMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["u"]},
      ],
      name: "Spannung [V]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusRTUMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["i"]},
      ],
      name: "Strom [A]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusRTUMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["p"]},
      ],
      name: "Wirkleistung [W]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusRTUMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["cosphi"]},
      ],
      name: "cos(phi)",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusRTUMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["f"]},
      ],
      name: "Frequenz [Hz]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusRTUMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["activeEnergy"]},
      ],
      name: "Energie [kWh]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
  ]
}

export const modbusTCPMeterChart = (name) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusTCPMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["u"]},
      ],
      name: "Spannung [V]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusTCPMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["i"]},
      ],
      name: "Strom [A]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusTCPMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["p"]},
      ],
      name: "Wirkleistung [W]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusTCPMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["cosphi"]},
      ],
      name: "cos(phi)",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusTCPMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["f"]},
      ],
      name: "Frequenz [Hz]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ModbusTCPMeter"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["di"]},
      ],
      name: "Digitaler Eingang",
      sortByKey: "tags_4",
      type: "TimeSeriesLineChart",
    },
  ]
}

export const dsmr5Chart = (name) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["DSMR5"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["actualPowerConsumption", "actualPowerProduction"]},
      ],
      name: "Leistung [kW]",
      sortByKey: "tags_4",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["DSMR5"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["energyConsumedTariff1", "energyConsumedTariff2"]},
      ],
      name: "Energie Bezug [kWh]",
      sortByKey: "tags_4",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["DSMR5"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["voltageL1", "voltageL2", "voltageL3"]},
      ],
      name: "Spannung [V]",
      sortByKey: "tags_4",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["DSMR5"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["currentL1", "currentL2", "currentL3"]},
      ],
      name: "Strom [A]",
      sortByKey: "tags_4",
      type: "TimeSeriesLineChart",
    },
  ]
}

export const rpiGpio = [
  {
    filters: [
      {name: "tags_1", value: ["devices"]},
      {name: "tags_2", value: ["RpiGPIO"]},
    ],
    name: "GPIO",
    sortByKey: "tags_3",
    type: "TimeSeriesLineChart",
  }
]

export const easeeCircuitChart = (siteId, circuitId) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["easeeCircuit"]},
        {name: "tags_2", value: [siteId]},
        {name: "tags_3", value: [circuitId]},
        {name: "tags_4", value: ["actualCurrent"]},
      ],
      name: "Aktueller Strom [A]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["easeeCircuit"]},
        {name: "tags_2", value: [siteId]},
        {name: "tags_3", value: [circuitId]},
        {name: "tags_4", value: ["dynamicCurrent"]},
      ],
      name: "Limite [A]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    }
  ]
}

export const zaptecInstallationChart = (installationId) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["zaptecInstallation"]},
        {name: "tags_2", value: [installationId]},
        {name: "tags_3", value: ["actualCurrent"]},
      ],
      name: "Aktueller Strom [A]",
      sortByKey: "tags_4",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["zaptecInstallation"]},
        {name: "tags_2", value: [installationId]},
        {name: "tags_3", value: ["dynamicCurrent"]},
      ],
      name: "Limite [A]",
      sortByKey: "tags_4",
      type: "TimeSeriesLineChart",
    }
  ]
}

export const alfenSCNChart = (ip) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["alfenSCN"]},
        {name: "tags_2", value: [ip]},
        {name: "tags_3", value: ["actualCurrent"]},
      ],
      name: "Aktueller Strom [A]",
      sortByKey: "tags_4",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["alfenSCN"]},
        {name: "tags_2", value: [ip]},
        {name: "tags_3", value: ["dynamicCurrent"]},
      ],
      name: "Limite [A]",
      sortByKey: "tags_4",
      type: "TimeSeriesLineChart",
    }
  ]
}

export const alfenSocketChart = (ip, socketId) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["alfenSocket"]},
        {name: "tags_2", value: [ip]},
        {name: "tags_3", value: [socketId]},
        {name: "tags_4", value: ["actualCurrent"]},
      ],
      name: "Aktueller Strom [A]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["alfenSocket"]},
        {name: "tags_2", value: [ip]},
        {name: "tags_3", value: [socketId]},
        {name: "tags_4", value: ["dynamicCurrent"]},
      ],
      name: "Limite [A]",
      sortByKey: "tags_4",
      type: "TimeSeriesLineChart",
    }
  ]
}

export const technagonACChart = (ip, socketId) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["technagonAC"]},
        {name: "tags_2", value: [ip]},
        {name: "tags_3", value: [socketId]},
        {name: "tags_4", value: ["actualCurrent"]},
      ],
      name: "Aktueller Strom [A]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["technagonAC"]},
        {name: "tags_2", value: [ip]},
        {name: "tags_3", value: [socketId]},
        {name: "tags_4", value: ["dynamicCurrent"]},
      ],
      name: "Limite [A]",
      sortByKey: "tags_4",
      type: "TimeSeriesLineChart",
    }
  ]
}

export const alpitronicHyperchargerChart = (ip) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["alpitronicHypercharger"]},
        {name: "tags_2", value: [ip]},
        {name: "tags_3", value: ["chargingPower"]},
      ],
      name: "Current charging Power [W]",
      sortByKey: "tags_3",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["alpitronicHypercharger"]},
        {name: "tags_2", value: [ip]},
        {name: "tags_3", value: ["maxPower"]},
      ],
      name: "SetMaxPower [W]",
      sortByKey: "tags_3",
      type: "TimeSeriesLineChart",
    }
  ]
}

export const shellyProMeterChart = (name) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ShellyPro3EM"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["u"]},
      ],
      name: "Spannung [V]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ShellyPro3EM"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["i"]},
      ],
      name: "Strom [A]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ShellyPro3EM"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["p"]},
      ],
      name: "Wirkleistung [W]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ShellyPro3EM"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["cosphi"]},
      ],
      name: "cos(phi)",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["ShellyPro3EM"]},
        {name: "tags_3", value: [name]},
        {name: "tags_4", value: ["f"]},
      ],
      name: "Frequenz [Hz]",
      sortByKey: "tags_5",
      type: "TimeSeriesLineChart",
    },
  ]
}

export const SGrSubMeterElectricityChart = (name) => {
  return [
    {
      filters: [
        {name: "tags_1", value: ["devices"]},
        {name: "tags_2", value: ["SGrDevice"]},
        {name: "tags_3", value: ["SubMeterElectricity"]},
        {name: "tags_4", value: [name]},
        {name: "tags_5", value: ["CurrentAC"]},
      ],
      name: "Strom [A]",
      sortByKey: "tags_6",
      type: "TimeSeriesLineChart",
    },
  ]
}