import React, {useEffect} from "react";

import {Table, Form} from 'react-bootstrap';

const RpiGPIOTable = (props) => {

  return (
    <Table>
      <tbody>
      <tr>
        <th className="text-start">Name</th>
        <td className="text-end">{props.device.name}</td>
      </tr>
      <tr>
        <th className="text-start">Direction</th>
        <td className="text-end">{props.device.direction}</td>
      </tr>
      <tr>
        <th className="text-start">GPIO</th>
        <td className="text-end">{props.device.gpio}</td>
      </tr>
      </tbody>
    </Table>
  )
}

const RpiGPIOForm = (props) => {
  
  // verify all fields are filled before saving
  useEffect(() => {
    if (props.device.name && props.device.direction && props.device.gpio) {
      props.setSaveDisabled(false);
    } else {
      props.setSaveDisabled(true);
    }
  }, [props.device]);

  return (
    <Form>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={props.device.name}
          onChange={props.handleChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>GPIO</Form.Label>
        <Form.Select
          name="direction"
          value={props.device.direction}
          onChange={props.handleChange}
        >
          <option value="select" disabled>auswählen...</option>
          <option value="in">in</option>
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>GPIO</Form.Label>
        <Form.Select
          name="gpio"
          value={props.device.gpio}
          onChange={props.handleChange}
        >
          <option value="select" disabled>auswählen...</option>
          <option value="23">23</option>
        </Form.Select>
      </Form.Group>
    </Form>
  )
}

export {RpiGPIOForm, RpiGPIOTable};