import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Spinner, Alert, Table, Badge } from 'react-bootstrap';
import { getAuth } from "firebase/auth";

const colors = ["#003f5c", "#bc5090", "#ffa600"];

const limit = 20;

const AggregatedLogsChart = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getEnd = () => {
    if (props.end === undefined){
      return (new Date()).toISOString();
    }
    return props.end
  };


  const getData = async() => {
    setLoading(true);
    const idTokenResult = await getAuth().currentUser.getIdTokenResult();
    //console.log(`start: ${props.start}`)
    //console.log(`end: ${getEnd()}`)
    const postData = {
      id: props.eid,
      start: props.start,
      stop: getEnd(),
      limit: limit
    };

    const headers = {
      'Authorization': 'Bearer ' + idTokenResult.token,
    };
    axios.post('https://influxapi.smartchargecontroller.ch/v1/getAggregatedLogs', postData, {headers}).then(response => {
      console.log(response.data);
      setData(response.data || []);
      setLoading(false)
    }).catch(err => console.log(err));
  }

  const buildTagsString = (d) => {
    let tags = "";
    for(let i = 0; i < 10; i++) {
      if(d[`tags_${i}`]) {
        tags += `${d[`tags_${i}`]}/`;
      }
    }
    return tags;
  }

  useEffect(() => {
    if(props.start && props.end) {
      getData();
    }
  }, [props.start, props.end]);

  return (
    <div>
      {loading ? <Spinner animation="border" /> :<>
        {
          data.length === 0 ? <Alert variant="light">Keine Logs im ausgewählten Zeitraum verfügbar</Alert> : <>
            <Table hover size="sm">
              <tbody style={{fontSize: "0.875rem"}}>
                {data.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td><Badge pill bg={d.level == "debug" ? "info" : d.level == "log" ? "warning" : "danger"}>{d.level}</Badge></td>
                      <td>{(new Date(d._time)).toLocaleString("de-CH")}</td>
                      <td className="truncate-text">{buildTagsString(d)}</td>
                      <td className="truncate-text">{d._value}</td>
  
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <div className="text-end">
              <small>Pro log-level werden die letzten {limit} Nachrichten angezeigt</small>
            </div>
          </>
        }

      </>
      }
    </div>
  );
}

export default AggregatedLogsChart;
