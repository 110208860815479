import {Card, ListGroup} from "react-bootstrap";
import React from "react";
import Icon from "../../icon";
import {ChargerTypes} from "../../../constants/evChargerConstants";
import {useData} from "../../../context/dataContext";
import {NodeTypes} from "../../../constants/nodeTypes";
import NodeDropdown from "../dropdown";

const EvCharger = ({nodeDatum}) => {

  const content = () => {
    let evType = Object.keys(nodeDatum?.attributes)[0];

    let extraContent;
    switch (evType) {
      case ChargerTypes.EASEE:
        extraContent = (
         <>
           <ListGroup.Item>
             <p className='mb-0'>CircuitId: {nodeDatum.attributes[evType]?.circuitId}</p>
             <p className='mb-0'>SiteId: {nodeDatum.attributes[evType]?.siteId}</p>
           </ListGroup.Item>
         </>)
        break;
      case ChargerTypes.ZAPTEC:
        extraContent = (
         <>
           <ListGroup.Item>
             <p className='mb-0'>InstallationId: {nodeDatum.attributes[evType]?.installationId}</p>
           </ListGroup.Item>
         </>
        )
        break;
      case ChargerTypes.ALFEN:
      case ChargerTypes.ALPITRONIC:
        extraContent = (
         <>
           <ListGroup.Item>
             <p className='mb-0'>IP: {nodeDatum.attributes[evType]?.ip}</p>
           </ListGroup.Item>
         </>
        )
        break;
      case ChargerTypes.ALFEN_SOCKET:
      case ChargerTypes.TECHNAGON_AC:
        extraContent = (
          <>
            <ListGroup.Item>
              <p className='mb-0'>IP: {nodeDatum.attributes[evType]?.ip}</p>
              <p className='mb-0'>Socket / Charging Point ID: {nodeDatum.attributes[evType]?.socketId}</p>
            </ListGroup.Item>
          </>
         )
         break;
    }
    return (
     <ListGroup className="list-group-flush">

       <ListGroup.Item>
         <p className='mb-0'><b>{evType}</b></p>
       </ListGroup.Item>
       {extraContent}
     </ListGroup>
    )
  }

  const [isHidden, setIsHidden] = React.useState(true);
  const handleIconClick = () => {
    setIsHidden(!isHidden)
  };

  return (
   <Card style={{textAlign: 'left'}} className="shadow mb-3 rounded-2 border-2">
     <Card.Body>
       <Card.Title className="mb-0 d-flex justify-content-between align-items-start">
         <div>
           <small style={{fontSize: 10}}>ID: {nodeDatum.name}</small>
           <br/>
           EvCharger
         </div>
         <div style={{position: 'relative'}} onClick={handleIconClick}>
           <div>
             <NodeDropdown isHidden={isHidden} nodeData={nodeDatum}/>
           </div>
           <div className="icon-hover">
             <Icon name="evStation" color={"#0d6efd"} size={30}/>
           </div>
         </div>
       </Card.Title>
     </Card.Body>
     {content()}
   </Card>
  )
}

export default EvCharger