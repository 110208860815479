import React, {useState, useEffect} from 'react';

import {Spinner, Form, Button, ButtonGroup, InputGroup, Row, Col} from 'react-bootstrap';
import {Pencil, X, Check, NodePlus, Cpu, FiletypeJson} from 'react-bootstrap-icons';

import MonacoEditor from "@monaco-editor/react";

import {doc, addDoc, setDoc, getDocs, collection, getDoc, query, orderBy} from 'firebase/firestore';
import {db} from '../Firebase';

import moment from 'moment';
import {sccSchemaPublicV1} from '../_helpers/scc.schema.public.v1';

import {useData} from '../ConfigTree/context/dataContext';
import DeviceDefinition from '../ConfigTree/DeviceDefinition';
import ConfigTree from '../ConfigTree/ConfigTree';

const JsonConfig = (props) => {
  const [conf, setConf] = useState("");
  const [editMode, setEditMode] = useState(false);

  const [configMode, setConfigMode] = useState("tree"); // json, tree, device

  const [activeConfig, setActiveConfig] = useState(null);

  const [loading, setLoading] = useState(false);

  const [configs, setConfigs] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState(0);

  const {currentJsonText, updateCurrentJsonText} = useData();
  const [schemaUrl, setSchemaUrl] = useState();

  const [ sgrcontainer, setSgrcontainer ] = useState(false);

  const init = async () => {
    setLoading(true);
    await getConfigs();
    await getSGrContainerEnabled();
    setLoading(false);
  }

  const getSGrContainerEnabled = async () => {
    const edgeRef = doc(db, "edges", props.eid);
    const edgeDoc = await getDoc(edgeRef);
    if (edgeDoc.exists()) {
      setSgrcontainer(edgeDoc.data().sgrcontainer);
    }
  }


  const getConfigs = async () => {
    let confs = [];
    const q = query(collection(db, "edges", props.eid, 'configurations'), orderBy("created", "desc"));
    const configRefs = await getDocs(q);
    configRefs.forEach(doc => {
      if (doc.id !== "configText") {
        const docData = doc.data();
        confs.push({ref: doc.ref, created: docData.created, value: docData.value});
      }
    })
    confs = confs.sort((a, b) => a.created + b.created)
    setConfigs(confs);
    confs[0]?.value && updateCurrentJsonText(confs[0].value)

    const activeConfig = await getDoc(doc(db, "edges", props.eid, "activeConfig", "v1"));
    if (activeConfig.exists()) setActiveConfig(activeConfig.data().created.toDate());
  }

  const saveConfig = async () => {
    setLoading(true);
    await addDoc(collection(db, "edges", props.eid, "configurations"), {value: currentJsonText, created: new Date()});
    await getConfigs();
    setLoading(false);
  }

  const saveAndActivateConfig = async () => {
    setLoading(true);
    let currentTime = new Date();
    await addDoc(collection(db, "edges", props.eid, "configurations"), {value: currentJsonText, created: currentTime});
    await setDoc(doc(db, "edges", props.eid, "activeConfig", "v1"), {
      value: currentJsonText,
      created: currentTime
    });

    init();
  }

  const onDropdownChange = (e) => {
    setSelectedConfig(e.target.value);
    configs[e.target.value]?.value && updateCurrentJsonText(configs[e.target.value].value);
  }

  const onTextAreaClick = (e) => {
    e.ctrlKey && setEditMode(true)
  }

  const onMonacoEditorChange = (e) => {
    //console.log(e)
    updateCurrentJsonText(e)
  }

  const activateConfig = async () => {
    setLoading(true);
    await setDoc(doc(db, "edges", props.eid, "activeConfig", "v1"), {
      value: configs[selectedConfig].value,
      created: configs[selectedConfig].created
    });
    await getConfigs();
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, [])

  return (
    loading ? <Spinner animation="border" size="sm"/> :
      <>
        <p className='float-end'>Active Config: {activeConfig && moment(activeConfig).format('DD.MM.YYYY HH:mm:ss')}</p>
        <Form>
          <InputGroup>
            <Form.Select aria-label="Default select example" onChange={onDropdownChange}>
              {configs.map((c, index) => (
                <option key={index} value={index}>{moment(c.created.toDate()).format('DD.MM.YYYY HH:mm:ss')}</option>))}
            </Form.Select>
            <Button variant="outline-primary" id="button-addon2" onClick={activateConfig}>
              Aktivieren
            </Button>
          </InputGroup>
        </Form>
        <br/>
        <Row>
          <Col>
            <div className="float-start">
              <ButtonGroup>
                <Button variant={configMode === "tree" ? "primary" : "outline-primary"}
                        onClick={() => setConfigMode("tree")}><NodePlus/> Standortstruktur</Button>
                <Button variant={configMode === "device" ? "primary" : "outline-primary"}
                        onClick={() => setConfigMode("device")}><Cpu/> Gerätedefinition</Button>
                <Button variant={configMode === "json" ? "primary" : "outline-primary"}
                        onClick={() => setConfigMode("json")}><FiletypeJson/> Json Editor</Button>
              </ButtonGroup>
            </div>
          </Col>
          <Col>
            <div className="float-end">
              <ButtonGroup>
                <Button variant="outline-primary" onClick={() => {
                  setEditMode(false);
                  updateCurrentJsonText(configs[selectedConfig]?.value)
                }}>Verwerfen</Button>
                <Button variant="outline-primary" onClick={() => saveConfig()}>Speichern</Button>
                <Button variant="outline-primary" onClick={() => saveAndActivateConfig()}>Speichern & Aktivieren</Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
        <br/>
        <div className="flex-row-reverse w-100">
          {configMode === "tree" &&
            <ConfigTree/>
          }
          {configMode === "device" &&
            <DeviceDefinition sgrcontainer={sgrcontainer}/>
          }
          {configMode === "json" &&
            <MonacoEditor
              height="67vh"
              language="json"
              value={currentJsonText}
              onChange={onMonacoEditorChange}
              editorWillMount={(monaco) => {
                monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                  validate: true,
                  schemas: [{
                    uri: "https://smartchargecontroller.ch/scc.schema.json",
                    fileMatch: ["*"],
                    schema: sccSchemaPublicV1
                  }]
                });
              }}
            />
          }
        </div>
      </>
  )
}

export default JsonConfig;